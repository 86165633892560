<template>
  <div class="container-fluid mt-4 mb-5  d-flex" v-if="(this.$store.state.shop.shops).length>0" >
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            График по курьерам общий
          </div>
        </div>

        <div class="col-12">
          <ChartCouriersItemAll
              :shops="this.$store.state.shop.shops"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChartCouriersItem from "@/components/ui/сhartCouriers/item";
import ChartCouriersItemAll from "@/components/ui/сhartCouriers/itemAll.vue";
export default {
  name: 'ChartCouriers',
  components: {ChartCouriersItemAll, ChartCouriersItem},
  data() {
    return {

    }
  }
}
</script>
