<template>
  <div class="" v-if="this.shops?.[0]">
    <table class="table table-bordered">
      <thead>
      <tr>
        <th scope="col"></th>
        <th  v-for="power in this.shops[0].powers"
             :key="power.id"
             v-text="$store.getters['shop/intervalToText'](power.intervalAt)"
        />
      </tr>
      </thead>
      <tbody>
      <tr>
        <th>Заказов</th>
        <th style="text-align: center; cursor: pointer"  v-for="power in getSumShopsPowers()"
            :key="power.id"
            v-bind:style= "[(power.totalSalesDelivery < power.powerDelivery) ? {'background':'#e9f0c0'} : {'background':'#ea5d62','color':'white'}]"
            v-text="power.totalSalesDeliverySum "
        />
      </tr>
      <tr>
        <th>Остаток&nbsp;&nbsp;&nbsp;</th>
        <th style="text-align: center; cursor: pointer"  v-for="power in getSumShopsPowers()"
            :key="power.id"
            v-text="power.endCouriersPower"
        />
      </tr>
      </tbody>
    </table>
  </div>
</template>


<script>


export default {
  name: 'ChartCouriersItemAll',
  data() {
    return {

    }
  },
  props: {
    shops: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getSumShopsPowers() {
      const summedPowers = {};

      this.shops?.forEach(shop => {
        shop.powers.forEach(power => {
          const key = power.intervalAt; // Используем `intervalAt` как уникальный ключ

          if (!summedPowers[key]) {
            summedPowers[key] = { ...power }; // Копируем объект
          } else {
            console.log(power);
            summedPowers[key].value += power.value;
            summedPowers[key].powerDelivery += power.powerDelivery;
            summedPowers[key].endPower += power.endPower;
            summedPowers[key].endCouriersPower += power.endCouriersPower;
            summedPowers[key].totalSales += power.totalSales;
            summedPowers[key].totalSalesDelivery += power.totalSalesDelivery + power.transferSales;
            summedPowers[key].transferSales += power.transferSales;
            summedPowers[key].sum += power.sum;
            summedPowers[key].totalSum += power.totalSum;
            summedPowers[key].transfer += power.transfer;
            summedPowers[key].totalSalesDeliverySum += power.totalSalesDeliverySum;
            summedPowers[key].visibleCouriersText += power.visibleCouriersText;
            summedPowers[key].visibleText += power.visibleText;
          }
        });
      });

      return Object.values(summedPowers);
    }
  },
  mounted() {

  }
}
</script>
